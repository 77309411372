<script>
import BaseBack from '/~/components/base/back/base-back.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useEditablePage } from '/~/composables/cms/use-editable-page'
import { useProvider } from '/~/composables/provider'
import CardFindOutMore from '/~/templates/bill-payments/components/card-find-out-more.vue'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'
import BusinessList from './components/business-list.vue'

export default {
  name: 'fly-business-desktop',
  components: {
    BusinessList,
    BaseBack,
    BaseButton,
    CardFindOutMore,
  },
  setup() {
    const { providerTitle } = useProvider()
    const { selectedPartner } = usePointsPrograms()
    const { page } = useEditablePage()

    return {
      page,
      providerTitle,
      selectedPartner,
    }
  },
  methods: {
    addNewAccount() {
      this.$emit('add')
    },
  },
}
</script>

<template>
  <div class="flex min-h-full flex-col sm:flex-row">
    <div
      class="w-full grow rounded-b-3xl rounded-t-3xl bg-eonx-neutral-50 sm:w-2/3 sm:rounded-b-none"
    >
      <div class="mx-auto w-full space-y-5">
        <base-back :to="{ name: 'fly-partners-list' }" class="mr-auto" />
        <div class="mx-auto w-full max-w-xl">
          <div class="mb-14 mt-8 flex items-center justify-between">
            <span class="text-2xl font-bold text-eonx-neutral-800">
              Select member account
            </span>
            <base-button @click="addNewAccount()">
              + Add member account
            </base-button>
          </div>
          <business-list v-bind="$attrs" />
        </div>
      </div>
    </div>

    <div
      v-if="page && page.widgets.length > 0"
      class="w-max-96"
      :class="{ 'px-[30px]': page && page.widgets }"
    >
      <widgets-builder
        :widgets="page.widgets"
        side="right"
        class="mb-10 !space-y-5"
      />
    </div>
    <div
      v-else
      class="ml-[30px] mr-5 flex w-full space-x-5 px-2.5 sm:my-0 sm:w-1/3 sm:min-w-64 sm:max-w-64 sm:flex-col sm:space-x-0 sm:px-0 lg:ml-10 lg:mr-6 lg:min-w-[352px] lg:max-w-[352px]"
    >
      <card-find-out-more
        class="mb-[30px] flex min-h-56 flex-col items-start rounded-3xl pb-5 sm:mb-0 sm:min-h-[400px] sm:pl-5 sm:pr-5 sm:pt-5 lg:pb-10 lg:pl-10 lg:pr-10 lg:pt-10"
        :show-button="false"
      >
        <div class="text-xl font-bold leading-6 text-white">
          Redeem {{ providerTitle }} Points your way!
        </div>
        <div class="mt-[15px] leading-6 text-white">
          Use your {{ providerTitle }} Points to make personal Bill Payments or
          access rewards to spoil yourself or your loved ones.
        </div>
      </card-find-out-more>
    </div>
  </div>
</template>
